<template>
  <el-dialog title="小程序账号授权" :visible.sync="openDialog" width="395px" :show-close="true" append-to-body
    class="dialog-vertical">
    <div class="auth-content">
      <div class="code-box">
        <el-image v-if="codeImg" class="scan-code" :src="codeImg" fit="contain"></el-image>
        <div v-if="codeInvalid" class="err-mask cursor-pointer"><img src="../static/images/refresh-img@2x.png"
            @click="getAuthCode" /></div>
      </div>
      <p class="content-tip">{{!codeInvalid?'使用小程序绑定的管理员个人微信号扫描':authErr?'授权失败,请刷新二维码':'二维码失效，点击刷新'}}</p>
    </div>
  </el-dialog>
</template>

<script>
import { authCode, scanCodeStatus } from "../api/grant-auth";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    isUpate: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Function,
    },
  },
  data() {
    return {
      codeImg: "",
      codeInvalid: false,
      authErr: false,
      TimeoutTimer: null,
      intervalTimer: null,
    };
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.authErr = false;
        this.codeInvalid = false;
        if (this.TimeoutTimer) clearTimeout(this.TimeoutTimer);
        this.TimeoutTimer = setTimeout(() => {
          this.codeInvalid = true;
          if (this.TimeoutTimer) clearTimeout(this.TimeoutTimer);
          if (this.intervalTimer) clearTimeout(this.intervalTimer);
        }, 500000);
        this.getAuthCode();
        this.getUserHandleStauts();
      } else {
        if (this.TimeoutTimer) clearTimeout(this.TimeoutTimer);
        if (this.intervalTimer) clearTimeout(this.intervalTimer);
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    isAuth() {
      return this.$store.getters.authStatus;
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalTimer);
  },
  methods: {
    //获取二维码
    getAuthCode() {
      authCode({ is_update: this.isUpate ? 1 : 0 })
        .then((res) => {
          this.codeImg = res.data;
          this.authErr = false;
          this.codeInvalid = false;
        })
        .catch((err) => {});
    },
    // 获取用户扫码操作信息
    getUserHandleStauts() {
      if (this.isAuth) return;
      if (this.intervalTimer) clearTimeout(this.intervalTimer);
      this.intervalTimer = setInterval(() => {
        scanCodeStatus()
          .then((res) => {
            const { data } = res;
            if (data.is_scan) clearTimeout(this.intervalTimer);
            if (data.is_scan && data.auth_status) {
              this.$message.success("小程序授权成功");
              this.update();
              this.openDialog = false;
            } else if (data.is_scan && !data.auth_status) {
              this.codeInvalid = true;
              this.authErr = true;
            }
          })
          .catch(() => {});
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-content {
  text-align: center;
  padding: 0 47px 30px;

  .code-box {
    width: 230px;
    height: 230px;
    padding: 15px;
    margin: auto;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #c0c4cc;
    position: relative;

    .scan-code {
      width: 100%;
      height: 100%;
    }

    .err-mask {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(255, 255, 255, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 88px;
        height: 88px;
      }
    }
  }

  .content-tip {
    font-size: 14px;
    color: #303133;
  }
}
</style>
