/*
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-09-20 17:16:35
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-21 14:36:54
 */

import api from "@/base/utils/request";

// SAAS后台获取小程序码
export const unLimitAppCode = (data) => {
  return api({
    url: "/admin/wx_open/WxAppletAuth/unLimitAppCode",
    method: "post",
    data,
  });
};
// SAAS后台获取小程序二维码
export const wXAppletQrcode = (data) => {
  return api({
    url: "/admin/wx_open/WxAppletAuth/wXAppletQrcode",
    method: "post",
    data,
  });
};

// SAAS后台获取小程序头像
export const getAppAvatar = (data) => {
  return api({
    url: "/admin/wx_open/WxAppletAuth/getAppAvatar",
    method: "post",
    data,
  });
};

