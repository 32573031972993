var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"dialog-vertical view-dialog",attrs:{"title":_vm.title,"visible":_vm.show,"width":"800px"},on:{"update:visible":function($event){_vm.show=$event},"open":_vm.initDialog}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"content"},[_c('el-form',{staticClass:"demo-form-inline",attrs:{"inline":true,"model":_vm.form}},[_c('el-form-item',{attrs:{"label":"小程序码颜色：","label-width":"100px"}},[_c('el-select',{attrs:{"placeholder":"请选择"},on:{"change":_vm.selectColorType},model:{value:(_vm.form.color_type),callback:function ($$v) {_vm.$set(_vm.form, "color_type", $$v)},expression:"form.color_type"}},[_c('el-option',{attrs:{"label":"默认","value":0}}),_c('el-option',{attrs:{"label":"智能取色","value":1}}),_c('el-option',{attrs:{"label":"自定义颜色","value":2}})],1)],1),_c('el-color-picker',{attrs:{"popper-class":"color-picker","disabled":_vm.colorDisabled,"color-format":"rgb"},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}}),(_vm.form.color_type === 1)?_c('p',{staticClass:"tips"},[_vm._v(" 根据小程序头像主色调智能取色 ")]):_vm._e(),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(!_vm.colorDisabled),expression:"!colorDisabled"}],attrs:{"label":"RGB：","label-width":"50px"}},[_c('el-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1)],1),_c('el-table',{attrs:{"data":_vm.gridData,"cell-style":{ 'text-align': 'center' },"header-cell-style":{ 'text-align': 'center' }}},[_c('el-table-column',{attrs:{"property":"length","label":"边长(cm)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(((row.length) + "cm"))+" ")]}}])}),_c('el-table-column',{attrs:{"property":"distance","label":"建议扫描距离(m)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(((row.distance) + "m"))+" ")]}}])}),_c('el-table-column',{attrs:{"property":"wXAppletQrcode","label":"普通二维码"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('span',[_vm._v("普通二维码")]),_c('el-popover',{attrs:{"placement":"bottom","trigger":"hover"}},[_c('div',{staticClass:"tips-box"},[_c('div',{staticClass:"title"},[_vm._v("小程序二维码示例")]),_c('el-image',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":_vm.defaultWXAppletQrcode,"fit":"contain"}})],1),_c('i',{staticClass:"el-icon-question tips",attrs:{"slot":"reference"},slot:"reference"})])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('i',{staticClass:"el-icon-download download",on:{"click":function($event){return _vm.downloadWXAppletQrcode(row)}}})]}}])}),_c('el-table-column',{attrs:{"property":"unLimitAppCode","label":"小程序码"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('span',[_vm._v("小程序码")]),_c('el-popover',{attrs:{"placement":"bottom","trigger":"hover"}},[_c('div',{staticClass:"tips-box"},[_c('div',{staticClass:"title"},[_vm._v("小程序码示例")]),_c('el-image',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":_vm.defaultUnLimitAppCode,"fit":"contain"}})],1),_c('i',{staticClass:"el-icon-question tips",attrs:{"slot":"reference"},slot:"reference"})])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('i',{staticClass:"el-icon-download download",on:{"click":function($event){return _vm.downloadUnLimitAppCode(row)}}})]}}])})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"close-btn",attrs:{"type":"primary"},on:{"click":function($event){_vm.show = false}}},[_vm._v("关闭")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }