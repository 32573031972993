<template>
  <el-dialog title="提示" :visible.sync="openDialog" width="462px" class="dialog-vertical"
    @close="cancel('ver_code')">
    <el-form size="medium" :model="_data" :rules="rules" label-position="right" label-width="90px"
      class="small-form" ref="ver_code">
      <el-form-item label="短信验证码：" prop="ver_code" style="margin-bottom: 0;">
        <div class="form-item">
          <el-input style="width: 185px" v-model.number="ver_code" type="text" placeholder="请输入短信验证码">
          </el-input>
          <el-button :style="second?'background-color: #C0C4CC;border:none':''" type="primary"
            @click="getMessageCode">
            {{!second?'发送验证码':`重新发送(${second})`}}</el-button>
        </div>
        <p class="info"><span class="el-icon-info"></span>解除绑定，会造成当前小程序的重要信息丢失,请谨慎操作。</p>
        <el-checkbox class="is-confirm" v-model="isConfirm">已知晓解除绑定的风险，确认解绑
        </el-checkbox>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" :loading="saveLoading" @click="submit('ver_code')">解除绑定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addTester } from "../api/base-info.js";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: {
        ver_code: [
          { type: "number", message: "请输入短信验证码", trigger: "blur" },
        ],
      },
      isConfirm: false,
      ver_code: "",
      second: 0,
      timer: null,
      saveLoading: false,
    };
  },
  methods: {
    //获取短信验证码
    getMessageCode() {
      if (!this.second) {
        this.second = 60;
        this.timer = setInterval(() => {
          this.second--;
          if (this.second <= 0 && this.timer) clearInterval(this.timer);
        }, 1000);
      }
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isConfirm) {
          } else {
            this.$message.error("请点击知晓接触绑定的风险，确认解绑");
          }
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.$refs[formName].resetFields();
      if (this.timer) clearInterval(this.timer);
      this.second = 0;
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.info {
  margin-top: 15px !important;
  .el-icon-info {
    margin-right: 4px;
  }
}

.is-confirm {
  margin-top: 10px;
  ::v-deep.el-checkbox__label {
    font-size: 14px;
  }
}

.form-item {
  display: flex;
  width: 95%;
  justify-content: space-between;
}
</style>
