<!--
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-09-20 15:12:54
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-21 14:45:48
 *
 * 下载小程序码弹窗，通用组件只需要给个 v-model open 控制显示即可
 * @props { v-model } Boolean
-->

<template>
  <el-dialog
    :title="title"
    :visible.sync="show"
    width="800px"
    class="dialog-vertical view-dialog"
    @open="initDialog"
  >
    <div class="content" v-loading="loading">
      <!-- 筛选表单 -->
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="小程序码颜色：" label-width="100px">
          <el-select
            @change="selectColorType"
            v-model="form.color_type"
            placeholder="请选择"
          >
            <el-option label="默认" :value="0"></el-option>
            <el-option label="智能取色" :value="1"></el-option>
            <el-option label="自定义颜色" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <!-- 颜色选择器 -->

        <el-color-picker
          popper-class="color-picker"
          v-model="color"
          :disabled="colorDisabled"
          color-format="rgb"
        ></el-color-picker>
        <p v-if="form.color_type === 1" class="tips">
          根据小程序头像主色调智能取色
        </p>
        <el-form-item v-show="!colorDisabled" label="RGB：" label-width="50px">
          <el-input v-model="color" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <el-table
        :data="gridData"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column property="length" label="边长(cm)">
          <template slot-scope="{ row }">
            {{ `${row.length}cm` }}
          </template>
        </el-table-column>
        <el-table-column property="distance" label="建议扫描距离(m)">
          <template slot-scope="{ row }">
            {{ `${row.distance}m` }}
          </template>
        </el-table-column>
        <el-table-column property="wXAppletQrcode" label="普通二维码">
          <template slot="header" slot-scope="scope">
            <span>普通二维码</span>
            <el-popover placement="bottom" trigger="hover">
              <div class="tips-box">
                <div class="title">小程序二维码示例</div>
                <el-image
                  style="width: 100px; height: 100px"
                  :src="defaultWXAppletQrcode"
                  fit="contain"
                ></el-image>
              </div>
              <i class="el-icon-question tips" slot="reference"></i>
            </el-popover>
          </template>
          <template slot-scope="{ row }">
            <i
              @click="downloadWXAppletQrcode(row)"
              class="el-icon-download download"
            ></i>
          </template>
        </el-table-column>
        <el-table-column property="unLimitAppCode" label="小程序码">
          <template slot="header" slot-scope="scope">
            <span>小程序码</span>
            <el-popover placement="bottom" trigger="hover">
              <div class="tips-box">
                <div class="title">小程序码示例</div>
                <el-image
                  style="width: 100px; height: 100px"
                  :src="defaultUnLimitAppCode"
                  fit="contain"
                ></el-image>
              </div>
              <i class="el-icon-question tips" slot="reference"></i>
            </el-popover>
          </template>
          <template slot-scope="{ row }">
            <i
              @click="downloadUnLimitAppCode(row)"
              class="el-icon-download download"
            ></i>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button class="close-btn" type="primary" @click="show = false"
          >关闭</el-button
        >
      </span>
    </div>
  </el-dialog>
</template>

<script>
import {
  unLimitAppCode,
  wXAppletQrcode,
  getAppAvatar,
} from '../api/download-code'
import { randomString } from '@/base/utils/tool'
import ColorThief from 'colorthief'
export default {
  model: {
    prop: 'open',
    event: 'handleOpen',
  },
  props: {
    //是否显示
    open: {
      type: Boolean,
      required: true,
    },
    //标题
    title: {
      type: String,
      default: '小程序码下载',
    },
  },
  data() {
    return {
      // 表格数据
      gridData: [
        {
          // 边长
          length: 8,
          // 建议扫描距离
          distance: 0.5,
          // 普通二维码
          wXAppletQrcode: '',
          // 小程序码
          unLimitAppCode: '',
        },
        {
          // 边长
          length: 12,
          // 建议扫描距离
          distance: 0.8,
          // 普通二维码
          wXAppletQrcode: '',
          // 小程序码
          unLimitAppCode: '',
        },
        {
          // 边长
          length: 15,
          // 建议扫描距离
          distance: 1,
          // 普通二维码
          wXAppletQrcode: '',
          // 小程序码
          unLimitAppCode: '',
        },
        {
          // 边长
          length: 30,
          // 建议扫描距离
          distance: 1.5,
          // 普通二维码
          wXAppletQrcode: '',
          // 小程序码
          unLimitAppCode: '',
        },
        {
          // 边长
          length: 50,
          // 建议扫描距离
          distance: 2.5,
          // 普通二维码
          wXAppletQrcode: '',
          // 小程序码
          unLimitAppCode: '',
        },
      ],
      // 默认提示小程序码图
      defaultWXAppletQrcode: '',
      // 默认提示小程序二维码图
      defaultUnLimitAppCode: '',
      form: {
        // 边长
        length: 8,
        // 取色类型 0: 默认 1: 自动取色, 2: 自定义取色
        color_type: 0,
        // 自定义取色json
        line_color: {
          r: 0,
          g: 0,
          b: 0,
        },
        // 是否透明底色
        is_hyaline: 1,
      },
      // 是否禁用颜色选择器
      colorDisabled: true,
      // 选择器色值
      color: 'rgb(0, 0, 0)',
      // 小程序头像链接
      appAvatar: '',
      // 智能取色色值
      intelligentColor: '',
      loading: false,
    }
  },
  computed: {
    show: {
      get() {
        return this.open
      },
      set(val) {
        console.log(val)
        this.$emit('handleOpen', val)
      },
    },
    // 自定义取色json
    line_color() {
      // 获取 rgb 括号里面的值
      var str = this.color.match(/\((.+)\)/g)
      let rgbAry = RegExp.$1.split(',')
      let rgbObj = {
        r: rgbAry[0],
        g: rgbAry[1],
        b: rgbAry[2],
      }
      return rgbObj
    },
  },
  methods: {
    // 初始化对话框，获取提示的小程序图片
    initDialog() {
      this.loading = true
      // 获取默认小程序码提示图
      unLimitAppCode({ ...this.form }).then((res) => {
        console.log(res)

        this.defaultUnLimitAppCode = res.data
      })
      // 获取默认小程序二维码提示图
      wXAppletQrcode({ length: 8 }).then((res) => {
        console.log(res)
        this.defaultWXAppletQrcode = res.data
      })
      // 获取小程序头像
      getAppAvatar().then(async (res) => {
        this.appAvatar = res.data
        let that = this
        // 使用插件获取主题色
        const colorThief = new ColorThief()
        const img = new Image()
        img.addEventListener('load', function () {
          let color = colorThief.getColor(img)
          that.intelligentColor = `rgb(${color[0]},${color[1]},${color[2]})`
          that.loading = false
        })
        img.crossOrigin = 'Anonymous'
        img.src = this.appAvatar
      })
    },
    // 下载小程序二维码
    downloadWXAppletQrcode(row) {
      console.log(row)
      wXAppletQrcode({ length: row.length }).then((res) => {
        console.log(res.data)
        // 下载图片
        this.downloadFile(randomString(8), res.data)
      })
    },
    // 下载小程序码
    downloadUnLimitAppCode(row) {
      let postData = {
        length: row.length,
        color_type: this.form.color_type,
        line_color: this.line_color,
        is_hyaline: false,
      }
      unLimitAppCode(postData).then((res) => {
        // 下载图片
        this.downloadFile(randomString(8), res.data)
      })
    },
    urlToBase64(url) {
      return fetch(url)
        .then((response) => response.blob())
        .then(
          (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onloadend = () => resolve(reader.result)
              reader.onerror = reject
              reader.readAsDataURL(blob)
            })
        )
    },
    // 切换选择样式
    selectColorType(type) {
      console.log(type)
      switch (type) {
        // 默认颜色
        case 0:
          this.color = 'rgb(0, 0, 0)'
          this.colorDisabled = true
          break
        // 智能取色
        case 1:
          console.log(this.intelligentColor)
          this.color = this.intelligentColor
          console.log(this.color)
          this.colorDisabled = true
          break
        // 自定义颜色
        case 2:
          this.colorDisabled = false
          break
      }
    },
    // 下载文件
    downloadFile(fileName, content) {
      const blob = this.base64ToBlob(content) // new Blob([content]);
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, fileName)
      } else {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName

        //此写法兼容可火狐浏览器
        document.body.appendChild(link)
        const evt = document.createEvent('MouseEvents')
        evt.initEvent('click', false, false)
        link.dispatchEvent(evt)
        document.body.removeChild(link)
      }
    },
    // base64 转 Blob
    base64ToBlob(code) {
      const parts = code.split(';base64,')
      const contentType = parts[0].split(':')[1]
      const raw = window.atob(parts[1])
      const rawLength = raw.length
      const uInt8Array = new Uint8Array(rawLength)
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }
      return new Blob([uInt8Array], { type: contentType })
    },
  },
}
</script>

<style lang="scss" scoped>
.demo-form-inline {
  text-align: left;
  background-color: #f8f8f8;
  display: flex;
  // align-items: center;
  padding: 20px 15px;
  .tips {
    color: #909399;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .color-type {
    ::v-deep .el-input__inner {
      width: 40px;
      height: 40px;
      padding: 0;
      overflow: hidden;
    }
  }
  .color-picker {
    border-radius: 50%;
  }
}
.view-dialog {
  .content {
    padding: 10px 0;
    text-align: center;
    .close-btn {
      margin-top: 20px;
    }
    .download {
      cursor: pointer;
      font-size: 24px;
    }
    .tips {
      margin-left: 5px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
.tips-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    padding: 5px 0 10px;
  }
}

// el-color-picker 颜色选择器样式调整
::v-deep .el-color-picker__trigger {
  border-radius: 50%;

  .el-icon-close {
    display: none;
  }
  .el-color-picker__color-inner {
    border-radius: 50%;
  }
  .el-color-picker__color {
    border-radius: 50%;
  }
  .el-color-picker__icon {
    display: none;
  }
}
::v-deep .el-color-picker__mask {
  border-radius: 50%;
  background-color: transparent;
}
</style>
