import api from '@/base/utils/request';

// 获取授权码
export const authCode = data => {
  return api({
    url: '/admin/wx_open/wx_applet_auth/getQrcode',
    method: 'post',
    data
  });
};

// 获取授权状态
export const authStatus = data => {
  return api({
    url: '/admin/wx_open/wx_applet_auth/authStatus',
    method: 'post',
    data,
    notCancel: true
  });
};

// 获取用户扫码状态
export const scanCodeStatus = data => {
  return api({
    url: '/admin/wx_open/wx_applet_auth/getQrcodeStatus',
    method: 'post',
    data
  });
};