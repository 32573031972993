<template>
  <div class="confirm-content">
    <el-button class="add-member" @click="addMember">添加体验成员</el-button>
    <el-table v-loading="loading" :data="memberList" class="thead-light" stripe style="width: 100%"
      @sort-change="sortChange">
      <!-- 序号 -->
      <el-table-column width="80" type="index" :index="indexMethod" label="序号"></el-table-column>
      <!-- 操作 -->
      <el-table-column label="操作" :width="100" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="DeleteRow(scope.row)">删除</el-button>
        </template>
      </el-table-column>
      <template v-for="(th, index) in thead">
        <el-table-column :prop="th.prop" :min-width="th.minWidth || ''" :sortable="th.sortable" :key="index">
          <template slot="header" slot-scope="scope">
            <span v-if="th.prop === 'position'"><span style="color: red">*</span>{{th.label}}</span>
            <span v-else>{{th.label}}</span>
          </template>
          <template slot-scope="scope">
            <!-- 普通 -->
            <span>{{ scope.row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    <add-member-dialog v-model="openAddMember" :updateList="refreshList" />
  </div>
</template>

<script>
import { testerList, delTester } from "../api/base-info.js";
import Pagination from "@/base/components/Default/Pagination";
import AddMemberDialog from "./AddMemberDialog";
export default {
  components: { Pagination, AddMemberDialog },
  data() {
    return {
      loading: false,
      thead: [
        { label: "微信号", prop: "wechat_id", minWidth: 220 },
        { label: "时间", prop: "create_time", minWidth: 200, width: 220, sortable: true },
      ],
      filterForm: {
        page_size: 15,
        order_by: "create_time",
        is_desc: 1,
      },
      memberList: [],
      pageData: {},
      openAddMember: false,
    };
  },
  methods: {
    // 获取体验成员列表
    getTesterList(requestData) {
      this.loading = true;
      testerList(requestData)
        .then((res) => {
          const { data } = res;
          this.memberList = data.data;
          this.pageData = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击添加
    addMember() {
      this.openAddMember = true;
    },
    // 点击移除
    DeleteRow(row) {
      this.$msgbox
        .confirm("确定要删除该数据吗？删除后无法恢复。", "提示", {
          type: "error",
        })
        .then((res) => {
          const data = {
            wechat_id: row.wechat_id,
            id: row.id,
            userstr: row.userstr,
          };
          this.loading = true;
          delTester(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.refreshList();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch((err) => {});
    },
    // 序号计算
    indexMethod(index) {
      return index + 1;
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getBrandList(this.filterForm);
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getTesterList(pageData);
    },
    // 刷新列表
    refreshList() {
      this.getTesterList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
  },
  created() {
    this.getTesterList(this.filterForm);
  },
};
</script>

<style lang="scss" scoped>
.add-member {
  margin: 0 10px 20px;
}
</style>
