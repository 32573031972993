<template>
  <div class="content" v-loading="loading">
    <div class="no-auth" v-if="!isAuth">
      <auth-card :configData="cardConfig" :update="getAuthInfo"></auth-card>
    </div>
    <div class="is-auth" v-else>
      <el-form v-if="baseForm" size="medium" :model="baseForm" label-position="right" label-width="85px" class="medium-form">
        <div class="modular" style="padding-top: 20px; overflow-x: auto">
          <p class="modular-title">小程序信息</p>
          <div class="modular-content" style="min-width: 885px">
            <el-form-item label="小程序头像：" class="avatar-form" style="margin-bottom: 13px">
              <div class="form-content">
                <el-avatar class="wx-avatar" :size="56" :src="baseForm.base_info.head_img" />
              </div>
              <p class="form-tip" style="align-items: center">
                <span class="el-icon-info"></span>一个月内可申请修改5次（需前往微信公众平台修改）
              </p>
            </el-form-item>
            <el-form-item label="小程序名称：" style="margin-bottom: 6px">
              <div class="form-content">
                <p class="content-text">{{ baseForm.base_info.name | placeholder }}</p>
                <div v-if="baseForm.base_info.name" class="content-btn">
                  <p style="color: #ff5350" class="btn-tiem" @click="cancelAuth">解除授权</p>
                  <p style="color: #3479ef" class="btn-tiem" @click="handleReauthor">重新授权</p>
                </div>
              </div>
              <p class="form-tip"><span class="el-icon-info"></span>一个月内可申请修改5次（需前往微信公众平台修改）</p>
            </el-form-item>
            <el-form-item label="介绍：">
              <div class="form-content">
                <p class="content-text">
                  {{ baseForm.base_info.signature | placeholder }}
                </p>
              </div>
              <p class="form-tip">
                <span class="el-icon-info"></span>小程序发布后，非个人账号可通过认证方式改名（需前往微信公众平台修改）
              </p>
            </el-form-item>
            <el-form-item label="主体信息：">
              <div class="form-content">
                <p class="content-text">
                  {{ baseForm.base_info.principal_name | placeholder }}
                </p>
              </div>
              <p class="form-tip"><span class="el-icon-info"></span>企业法人及个体工商户</p>
            </el-form-item>
            <el-form-item label="小程序ID：">
              <div class="form-content">
                <p class="content-text">
                  {{ baseForm.base_info.authorizer_appid | placeholder }}
                </p>
              </div>
              <p class="form-tip"><span class="el-icon-info"></span>每个小程序ID特有的ID号</p>
            </el-form-item>
            <el-form-item label="服务类目：" style="margin-bottom: 37px">
              <div class="form-content" style="display: block">
                <p class="content-list" v-for="(item, index) in baseForm.categories" :key="index">
                  {{ item.first_name + " > " + item.second_name }}
                </p>
                <p v-if="!baseForm.categories || !baseForm.categories.length">{{ "" | placeholder }}</p>
              </div>
              <p class="form-tip">
                <span class="el-icon-info"></span>根据服务类目生成，向C端用户展示（需前往微信公众平台修改）
              </p>
            </el-form-item>
            <el-form-item label="小程序码：">
              <div class="form-content">
                <el-avatar
                  class="wx-code"
                  :size="170"
                  :src="fixImageUrl(baseForm.base_info.qrcode_url)"
                  fit="contain"
                  :style="{
                    'background-color': baseForm.base_info.qrcode_url ? 'white' : '',
                  }"
                />
              </div>
              <div class="form-btn">
                <el-popover
                  style="margin-left: 24px"
                  v-if="baseForm.design_status !== 1"
                  placement="top"
                  title="提示"
                  width="200"
                  v-model="pVisible"
                >
                  <p style="margin-bottom: 10px;">请先前往页面装修发布版本</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="pVisible = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="toDesignApp">前往</el-button>
                  </div>
                  <el-button slot="reference" style="margin-right: 18px">
                    <div class="btn-layout">
                      已选为体验版<img class="qr-code-btn" src="../../static/images/qr-code-icon.png" />
                    </div>
                  </el-button>
                </el-popover>

                <el-button
                  class="btn-item"
                  type="primary"
                  @click="openExperienceQrCode = true"
                  v-if="baseForm.design_status === 1"
                  >获取体验码
                </el-button>
                <el-button class="btn-item" type="primary" @click="toDesignApp" v-if="baseForm.base_info.app_design_enable"
                  >去装修页面</el-button
                >
                <el-button class="btn-item" type="primary" @click="openDownloadCodeDialog = true"
                  >下载小程序码</el-button
                >
              </div>
            </el-form-item>
          </div>
        </div>
        <div class="modular">
          <p class="modular-title">体验成员列表</p>
          <div class="modular-content">
            <Reminder
              text="邀请非管理员体验时，请绑定微信用户为小程序体验成员，然后再点击获取体验码进行体验，小程序管理员直接点击获取体验码，使用微信扫一扫即可体验。"
            />
            <member-list />
          </div>
        </div>
        <ExperienceQRCode v-model="openExperienceQrCode" :qrCode="baseForm.qrcode" />
        <AuthDialog v-model="openAuthQRcode" :isUpate="reauthorize" :update="getAuthInfo" />
          <!-- 下载小程序码弹窗 -->
          <DownloadCodeDialog  v-model="openDownloadCodeDialog" />
        <!-- <CancelAuthDialog v-model="openCancelAuth" /> -->
      </el-form>
      <dev-auth-guide v-if="baseForm && !baseForm.base_info.app_dev_auth" :info="baseForm.base_info" />
    </div>
  </div>
</template>

<script>
import AuthCard from "../../components/AuthCard.vue";
import MemberList from "../../components/MemberList.vue";
import { authInfo, cancleAuth } from "../../api/base-info.js";
import { authStatus } from "../../api/grant-auth.js";
import ExperienceQRCode from "../../components/ExperienceQRCode.vue";
import AuthDialog from "../../components/AuthDialog.vue";
// 下载小程序码弹窗
import DownloadCodeDialog from "../../components/DownloadCodeDialog.vue";
import CancelAuthDialog from "../../components/CancelAuthDialog.vue";
import Reminder from "../../../common/components/Reminder.vue";
import DevAuthGuide from "@/modules/wechat-app/components/DevAuthGuideDialog";
export default {
  components: {
    DevAuthGuide,
    AuthCard,
    MemberList,
    ExperienceQRCode,
    AuthDialog,
    CancelAuthDialog,
    Reminder,
    DownloadCodeDialog
  },
  data() {
    return {
      // 未授权页面样式配置
      cardConfig: [
        {
          id: 0,
          icon: require("../../static/images/is-auth-icon@2x.png"),
          title: "我已拥有小程序",
          discription: "将微信小程序授权给我们平台，您不需要做复杂操作，即可自动提交到微信审核",
          btnText: "授权微信小程序",
        },
        {
          id: 1,
          icon: require("../../static/images/no-auth-icon@2x.png"),
          title: "我还没有小程序",
          discription: "如需使用微信支付，请申请企业主体的小程序",
          btnText: "前往微信公众平台申请",
        },
      ],
      loading: false,
      reauthorize: false,
      timer: null,
      timeoutTimer: null,
      /**
       * app_dev_auth": 1,        // 是否已授权小程序开发权限
       * app_dev_auth_name: "小程序开发与数据分析"
       */
      baseForm: null,
      openExperienceQrCode: false,
      openAuthQRcode: false,
      openCancelAuth: false,
      pVisible: false,
      openDownloadCodeDialog:false
    };
  },
  watch: {
    isAuth(val) {
      if (val && this.timer) clearInterval(this.timer);
    },
    openAuthQRcode(val) {
      if (val) {
        this.getAuthStatus();
        if (!this.timeoutTimer) {
          this.timeoutTimer = setInterval(() => {
            if (this.timer) {
              clearInterval(this.timer);
              this.$msgbox
                .confirm("您于5分钟内未完成授权操作，请重新打开二维码授权", "提示", {
                  type: "warning",
                  showCancelButton: false,
                })
                .then((res) => {
                  this.openAuthQRcode = false;
                  this.reauthorize = false;
                })
                .catch((err) => {});
            }
            if (this.timeoutTimer) {
              clearTimeout(this.timeoutTimer);
            }
          }, 300000);
        }
      }
    },
  },
  computed: {
    isAuth() {
      return this.$store.getters.authStatus;
    },
  },
  methods: {
    // 获取小程序授权状态
    getAuthStatus() {
      if (this.isAuth && !this.reauthorize) return;
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(() => {
        authStatus()
          .then((res) => {
            const { data } = res;
            // console.log("status", data.status, this.reauthorize);
            this.$store.commit("setAuthStatus", data.status ? 1 : 0);
            if (((data.status === 1 && !this.reauthorize) || (data.status === 2 && this.reauthorize)) && this.timer) {
              clearInterval(this.timer);
            }
            if (data.status === 2 && this.reauthorize) {
              this.$message.success(res.msg);
              this.reauthorize = false;
              this.openAuthQRcode = false;
              this.getAuthInfo();
            }
          })
          .catch(() => {});
      }, 5000);
    },
    // 获取小程序基本信息
    getAuthInfo() {
      this.loading = true;
      authInfo()
        .then((res) => {
          this.baseForm = res.data;
          console.log("baseForm: ", this.baseForm);
          this.$store.commit("setAuthStatus", res.data ? 1 : 0);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 重新授权
    handleReauthor() {
      this.openAuthQRcode = true;
      this.reauthorize = true;
    },
    // 取消授权
    cancelAuth() {
      this.$msgbox
        .confirm("是否解除授权?", "提示", {
          type: "info",
        })
        .then((res) => {
          cancleAuth()
            .then((res) => {
              this.$message.success(res.msg);
              this.getAuthInfo();
            })
            .catch(() => {});
        })
        .catch((err) => {});
    },

    // 去页面装修
    toDesignApp() {
      this.$router.push({ name: "AppDesignIndex" });
    },
  },
  created() {
    if (this.isAuth) {
      this.getAuthInfo();
    }
  },
  beforeDestroy() {
    // console.log("beforeDestroy", this.timer);
    if (this.timer) clearInterval(this.timer);
    if (this.timeoutTimer) clearTimeout(this.timeoutTimer);
  },
};
</script>

<style lang="scss" scoped>
.content {
  /*min-width: 985px;*/
  min-height: 400px;
}

.no-auth {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -20px;
  background-color: #f5f6f7;
  min-height: calc(100vh - 150px);
}

.btn-layout {
  display: flex;
  align-items: center;
}

.qr-code-btn {
  width: 12px;
  height: 12px;
  margin-left: 4px;
}

.is-auth {
  .modular + .modular {
    margin-top: 85px;
  }
  .modular-content {
    margin-left: 11px;
    .el-form-item {
      margin-bottom: 2px;
      ::v-deep.el-form-item__label {
        display: inline-block;
        margin: auto 0;
      }
      ::v-deep.el-form-item__content {
        display: flex;
        // align-items: center;

        .form-content {
          display: flex;
          flex-shrink: 0;
          // align-items: center;
          width: 50%;
          padding-right: 85px;

          .content-text {
            font-size: 14px;
            line-height: 36px;
            color: rgba(0, 0, 0, 0.65);
          }

          .content-list {
            font-size: 14px;
            line-height: 36px;
            color: rgba(0, 0, 0, 0.65);
          }

          .content-list + .content-list {
            margin-top: -8px;
          }

          .content-btn {
            display: flex;
            margin-left: 58px;
            .btn-tiem {
              font-size: 14px;
              cursor: pointer;
            }
            .btn-tiem + .btn-tiem {
              margin-left: 20px;
            }
          }
        }

        .form-btn {
          display: flex;
          padding-right: 108px;
          align-items: center;
          .btn-item + .btn-item {
            margin-left: 20px;
          }
          .el-icon-question {
            color: #3479ef;
            font-size: 18px;
          }
        }

        .form-tip {
          display: flex;
          flex-shrink: 0;
          // align-items: center;
          width: 440px;
          font-size: 12px;
          color: #606266;

          .el-icon-info {
            display: inline-block;
            font-size: 16px;
            margin-right: 4px;
            color: #c0c4cc;
            line-height: 36px;
          }
        }
      }
    }
  }
}

.avatar-form {
  ::v-deep.el-form-item__label {
    line-height: 56px;
  }
}
</style>
