<template>
  <div class="cover-view" :class="{isCollapse}">
    <p slot="title" class="dialog-vertical-title align-center">缺少 “{{info.need_auth_fun.join('、')}}” 权限，请参照图示重新授权权限<br/>
      如权限集的「{{info.app_dev_auth_name}}」无法勾选，请先在微信小程序后台取消第三方平台授权
      <el-link href="https://www.yuque.com/ax101w/yz6xgk/bp48hv#nRDgW" target="_blank" type="primary"
               style="font-size: 18px; vertical-align: baseline;">查看帮助</el-link>
    </p>
    <div class="steps flex">
      <div class="step-item flex-col flex-align-center">
        <span class="step">1</span>
        <el-avatar class="code cursor-pointer" shape="square" :src="codeImg" :size="198" @click.native="getCode" />
        <p>重新扫码授权小程序</p>
      </div>
      <div class="step-divider flex-center">
        <i class="el-icon-right" />
      </div>
      <div class="step-item flex-col flex-align-center">
        <span class="step">2</span>
        <img class="screenshot cursor-pointer" :src="dev2" @click="$previewImage([dev2, dev3])" />
        <p>修改权限集</p>
      </div>
      <div class="step-divider flex-center">
        <i class="el-icon-right" />
      </div>
      <div class="step-item flex-col flex-align-center">
        <span class="step">3</span>
        <img class="screenshot cursor-pointer" :src="dev3" @click="$previewImage([dev3, dev2])" />
        <p>全选【全部权限】</p>
      </div>
    </div>
  </div>
</template>

<script>
import {authCode} from "@/modules/wechat-app/api/grant-auth";

export default {
  name: 'dev-auth-guide',
  props: {
    info: Object
  },
  data() {
    return {
      codeImg: '',
      dev2: require('../static/images/dev-2.png'),
      dev3: require('../static/images/dev-3.png')
    }
  },
  computed: {
    // 是否收缩侧边栏
    isCollapse() {
      return this.$store.getters.isCollapse;
    },
  },
  created() {
    this.getCode()
  },
  methods: {
    // 获取二维码
    getCode() {
      authCode({ is_update: 0 })
        .then((res) => {
          this.codeImg = res.data;
        })
        .catch(() => {});
    }
  },
};
</script>

<style lang="scss" scoped>
.cover-view {
  position: fixed;
  width: 100vw;
  height: calc(100vh - 60px - 50px);
  left: 0;
  top: calc(60px + 50px);
  /*background: rgba(0, 0, 0, .4);*/
  background-color: #c1c1c1;
  z-index: 9;
  padding: 40px 120px 40px $sidebar-w + 100px;

  &.isCollapse {
    padding-left: $mini-sidebar-w;
  }

  .align-center {
    text-align: center;
  }

  .dialog-vertical-title {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    line-height: 1.5;
  }

  .steps {
    width: 100%;
    margin-top: 32px;
  }

  .step-item {
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    line-height: 1.15;
  }

  .flex-align-center {
    align-items: center;
  }

  .step {
    background-color: $primary;
    color: white;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border-radius: 50%;
  }

  .code {
    width: 198px;
    height: 198px;
    margin-top: 113px;
    margin-bottom: 24px;
  }

  .screenshot {
    /*max-width: 180px;*/
    max-width: 220px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .el-icon-right {
    font-size: 30px;
    color: grey;
  }
}
</style>
