<template>
  <div class="card">
    <div class="card-item" v-for="(card, index) in configData" :key="index">
      <div class="card-content">
        <el-image class="card-img" :src="card.icon" fit="contain" />
        <p class="card-title">{{card.title}}</p>
        <p class="card-discript">{{ card.discription}}</p>
        <el-button class="card-btn" type="primary" @click="handleAuthApp(card)">{{card.btnText}}</el-button>
        <div class="card-tip" v-if="index === 0"><span class="el-icon-warning tip-icon"></span>请不要授权个人、小商店主体类型
        </div>
      </div>
    </div>
    <auth-dialog v-model="openAuthDialog" :update="update" />
  </div>
</template>

<script>
import AuthDialog from "./AuthDialog.vue";
export default {
  components: { AuthDialog },
  props: {
    configData: {
      type: Array,
      required: true,
    },
    update: {
      type: Function,
    },
  },
  data() {
    return {
      openAuthDialog: false,
    };
  },
  methods: {
    handleAuthApp(data) {
      if (data.id) {
        window.open("https://mp.weixin.qq.com/", "_blank");
      } else {
        this.openAuthDialog = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;

  .card-item {
    width: 364px;
    height: 398px;
    background: #ffffff;
    box-shadow: 0px 10px 18px 0px rgba(224, 229, 238, 0.5);
    border-radius: 10px;
    border: 1px solid #e3e3e3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 48px 40px;
    position: relative;
    .card-content {
      text-align: center;
      height: 100%;
      .card-img {
        width: 88px;
        height: 88px;
        margin-bottom: 45px;
      }
      .card-title {
        font-size: 22px;
        margin-bottom: 14px;
      }
      .card-discript {
        font-size: 14px;
        color: #606266;
        line-height: 21px;
      }
      .card-btn {
        position: absolute;
        bottom: 54px;
        transform: translate(-50%, 0);
      }
      .card-tip {
        position: absolute;
        display: flex;
        align-items: center;
        white-space: nowrap;
        bottom: 20px;
        left: 50%;
        font-size: 14px;
        color: #d30000;
        line-height: 14px;
        transform: translate(-50%, 0);
        .tip-icon {
          font-size: 16px;
          margin-right: 8px;
        }
      }
    }
  }

  .card-item + .card-item {
    margin-left: 68px;
  }
}
</style>
